import { useFlags } from 'launchdarkly-react-client-sdk';

type CottageFeatureFlags = {
  agreements: boolean;
  associateNewInvoiceWithWorkOrder: boolean;
  changeOrders: boolean;
  competitiveBidsFastFollows: boolean;
  designerHomeownerWorkOrder: boolean;
  docStorage: boolean;
  downtimeMessage: string;
  gcCompetitiveBids: boolean;
  gcProcurements: boolean;
  gcProcurementsMigration: boolean;
  gcProposals: boolean;
  homeownerSelfServe: boolean;
  kickoffCloseout: boolean;
  mobileAccess: boolean;
  paperChecks: boolean;
  projectTrackerTeamRoster: boolean;
  proposalExpiration: boolean;
  purchaseOrders: boolean;
  supportTab: boolean;
  upsellBanner: boolean;
};

export const useCottageFlags = useFlags<CottageFeatureFlags>;
